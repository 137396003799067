import { PandadocTemplateTag, PandadocTemplateId, PandadocCompanyTemplate } from "../models";

export const PANDADOC_SECTION_COUNT_TEMPLATE_TAG_TO_COUNT_MAP: Partial<Record<PandadocTemplateTag, number>> = {
    [PandadocTemplateTag.ONE_SECTION]: 1,
    [PandadocTemplateTag.TWO_SECTIONS]: 2,
    [PandadocTemplateTag.THREE_SECTIONS]: 3,
    [PandadocTemplateTag.FOUR_SECTIONS]: 4,
    [PandadocTemplateTag.FIVE_SECTIONS]: 5,
    [PandadocTemplateTag.SIX_SECTIONS]: 6,
    [PandadocTemplateTag.SEVEN_SECTIONS]: 7,
    [PandadocTemplateTag.EIGHT_SECTIONS]: 8,
    [PandadocTemplateTag.NINE_SECTIONS]: 9,
    [PandadocTemplateTag.TEN_SECTIONS]: 10,
};

export const MAX_PANDADOC_TEMPLATE_SECTIONS_PER_DOCUMENTS = 9;

export const PANDADOC_PROPERTY_TEMPLATE_IDS: PandadocTemplateId[] = [
    PandadocTemplateId.HYPO_LIEGENSCHAFT_EIGENTUMSWOHNUNG,
    PandadocTemplateId.HYPO_LIEGENSCHAFT_EINFAMILIENHAUS,
    PandadocTemplateId.ERSTE_HAUS,
    PandadocTemplateId.ERSTE_WOHNUNG,
    PandadocTemplateId.SBAU_LIEGENSCHAFTBESCHREIBUNG_HAUS,
    PandadocTemplateId.SBAU_LIEGENSCHAFTBESCHREIBUNG_WOHNUNG,
    PandadocTemplateId.VOLKSBANK_WIEN_IMMOBILIENBEWERTUNG,
];

export const PANDADOC_COMPANY_DEFAULT_TEMPLATES: Record<PandadocCompanyTemplate, string> = {
    [PandadocCompanyTemplate.financingRequestSingleBorrower]: PandadocTemplateId.GENERIC_FINANZIERUNGSANFRAGE_NEU_1KN,
    [PandadocCompanyTemplate.financingRequestMultiBorrower]: PandadocTemplateId.GENERIC_FINANZIERUNGSANFRAGE_NEU_2KN,
    [PandadocCompanyTemplate.contractSingleBorrower]: PandadocTemplateId.GENERIC_VERMITTLUNGSAUFTRAG_1KN,
    [PandadocCompanyTemplate.contractMultiBorrower]: PandadocTemplateId.GENERIC_VERMITTLUNGSAUFTRAG_2KN,
};
